// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-templates-biography-js": () => import("./../../../src/templates/biography.js" /* webpackChunkName: "component---src-templates-biography-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-feature-js": () => import("./../../../src/templates/feature.js" /* webpackChunkName: "component---src-templates-feature-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */)
}

